<template>
<div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-group">
            <label>Correo electrónico (*):</label>
            <input type="text" class="form-control" v-model="inb_user" disabled>
          </div>
          <div class="form-group">
            <label>Última fecha de consulta:</label>
            <input type="date" v-model="lastDate" class="form-control">
          </div>
          <!--
          <div class="form-group">
            <label>Hora de inicio:</label>
            <input type="time" v-model="item.hinicio" class="form-control">
          </div>
          -->
          <div class="form-group">
            <label>Último UID:</label>
            <input type="number" v-model="item.inb_lastUid" class="form-control">
          </div>
          <div class="form-group">
            <label>Último UID Validity:</label>
            <input type="number" v-model="item.inb_lastUidvalidity" class="form-control">
          </div>
          <div class="form-group">
            <label>Filtro:</label>
            <input type="text" v-model="item.inb_filter" class="form-control">
          </div>
          <div class="form-group">
            <input type="checkbox" class="form-checkbox-input" id="inb_sendNotification" v-model="item.inb_sendNotification">
            <label for="inb_sendNotification">Enviar notificación a remitente al radicar:</label>
          </div>
          <div class="form-group">
            <div>
              <label><b style="color:darkblue">VALORES PREDETERMINADOS PARA CLASIFICACIÓN</b></label>
            </div>
            <label>Clase de documento (*):</label>
            <select2 id="tcr_id" v-model="item.tcr_id" :options="tpocors" required />
          </div>
          <div class="form-group">
            <label>Tipo de documento (*):</label>
            <select2 id="tdo_id" v-model="item.tdo_id" :options="tpodocs" required />
          </div>
          <div class="form-row">
            <div class="form-group col-md-8">
              <label>Tipo de tabla (*):</label>
              <select2 id="tta_id" v-model="item.tta_id" :options="tpotablas" required />
            </div>
            <div class="form-group col-md-4">
              <label>Versión de tabla (*):</label>
              <input type="number" v-model="item.tav_nmro" class="form-control">
            </div>
          </div>
          <div class="form-group">
            <label>Dependencia (*):</label>
            <select2 id="dep_id" v-model="item.dep_id" :options="dependencias" required />
          </div>
          <div class="form-group">
            <label>Serie (*):</label>
            <select2 id="ser_id" v-model="item.ser_id" :options="series" required />
          </div>
          <div class="form-group">
            <label>Tipo de archivo (*):</label>
            <select2 id="tpa_id" v-model="item.tpa_id" :options="tpoarchs" required />
          </div>
          <div class="form-group">
            <label>Usuario (*):</label>
            <select2 id="usu_id" v-model="item.usu_id" :options="usuarios" required />
          </div>
          <div class="form-group">
            <label>Tiempo de respuesta (*):</label>
            <input type="number" v-model="item.dcm_tres" class="form-control">
          </div>
          <div class="form-group" id="_permisos">
            <label><b style="color:darkblue">PERMISOS DE RADICACIÓN</b></label>
            <div class="form-group">
              <a href="#" v-on:click.prevent="newPermiso()" class="col-md-6" data-toggle="modal" data-target="#permisoModal"><plus-circle-icon class="custom-class"></plus-circle-icon>Agregar</a>
              <create-permiso v-on:add-permiso="addPermiso(permiso)" v-bind:permiso="permiso"></create-permiso>
            </div>
            <div>
              <table class="table table-bordered">
                <tr>
                  <th>Usuario</th>
                  <th>Permitir envío</th>
                  <th></th>
                </tr>
                <tr v-for="(i, index) in item.permisos">
                  <td>{{ i.usu_nmbre }}</td>
                  <td>{{ i.inb_envio | formatBoolean }}</td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="editPermiso(index)" data-toggle="modal" data-target="#permiso" title="Editar"><edit-icon class="custom-class"></edit-icon></a>
                  </td>
                  <td align="center">
                    <a href="#" v-on:click.prevent="deletePermiso(index)" title="Eliminar"><delete-icon class="custom-class"></delete-icon></a>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import { formatBoolean } from '../../plugins/filters';
  import Select2 from '../select2/Select2.vue';
  //import moment from 'moment';
  import { PlusCircleIcon, EditIcon, DeleteIcon } from 'vue-feather-icons';
  import CreatePermiso from './CreatePermiso.vue';


  export default {
    components: {
      Select2,
      PlusCircleIcon, EditIcon, DeleteIcon,
      CreatePermiso
    },
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        tpocors: [],
        tpodocs: [],
        tpotablas: [],
        dependencias: [],
        series: [],
        tpoarchs: [],
        usuarios: [],
        message: '',
        buttontext: '',
        ready: false,
        pwd: {},
        inb_user: null,
        lastDate: null,
        permiso: {},
        addPerm: true,
        editPerm: -1,
      }
    },
    filters: {
      formatBoolean
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: async function() {
      this.fetchTpocor();
      this.fetchTpodoc();
      this.fetchTpotabla();
      this.fetchDependencia();
      this.fetchSerie();
      this.fetchTpoarch();
      await this.fetchUsuario();

      this.search = this.$route.params.search;
      
      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear un Buzón';
        this.buttontext = 'Agregar Buzón';
      } else {
        this.title = 'Editar un Buzón';
        this.buttontext = 'Actualizar Buzón';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      fetchTpocor(){
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tcr_nmbre + ' (' + a.tcr_cdgo + ')').toLowerCase(); 
            var tb = String(b.tcr_nmbre + ' (' + b.tcr_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpocors = list.map(i => ({ id: String(i._id), text: i.tcr_nmbre + ' (' + i.tcr_cdgo + ')' }));
        });
      },
      fetchTpodoc(){
        let uri = '/tpodocs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tdo_nmbre + ' (' + a.tdo_cdgo + ')').toLowerCase(); 
            var tb = String(b.tdo_nmbre + ' (' + b.tdo_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpodocs = list.map(i => ({ id: String(i._id), text: i.tdo_nmbre + ' (' + i.tdo_cdgo + ')' }));
        });
      },
      fetchTpotabla(){
        let uri = '/tpotablas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tta_nmbre + ' (' + a.tta_cdgo + ')').toLowerCase(); 
            var tb = String(b.tta_nmbre + ' (' + b.tta_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpotablas = list.map(i => ({ id: String(i._id), text: i.tta_nmbre + ' (' + i.tta_cdgo + ')' }));
        });
      },
      fetchDependencia(){
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.dep_nmbre + ' (' + a.dep_cdgo + ')').toLowerCase(); 
            var tb = String(b.dep_nmbre + ' (' + b.dep_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.dependencias = list.map(i => ({ id: String(i._id), text: i.dep_nmbre + ' (' + i.dep_cdgo + ')' }));
        });
      },
      fetchSerie(){
        let uri = '/series/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.ser_nmbre + ' (' + a.ser_cdgo + ')').toLowerCase(); 
            var tb = String(b.ser_nmbre + ' (' + b.ser_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.series = list.map(i => ({ id: String(i._id), text: i.ser_nmbre + ' (' + i.ser_cdgo + ')' }));
        });
      },
      fetchTpoarch(){
        let uri = '/tpoarchs/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.tpa_nmbre + ' (' + a.tpa_cdgo + ')').toLowerCase(); 
            var tb = String(b.tpa_nmbre + ' (' + b.tpa_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.tpoarchs = list.map(i => ({ id: String(i._id), text: i.tpa_nmbre + ' (' + i.tpa_cdgo + ')' }));
        });
      },
      async fetchUsuario(){
        try {
          let uri = '/usuarios/list';
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          var response = await this.axios.post(uri, { cmp_id: String(this.$store.state.company) });        
          var list = response.data;
          list.sort(function(a,b){
            var ta = String(a.usu_nmbre + ' ' + a.usu_aplldo + ' (' + a.usu_cdgo + ')').toLowerCase(); 
            var tb = String(b.usu_nmbre + ' ' + b.usu_aplldo + ' (' + b.usu_cdgo + ')').toLowerCase(); 
            if (ta > tb) { return 1; };
            if (ta < tb) { return -1; };
            return 0;
          });
          this.usuarios = list.map(i => ({ id: String(i._id), text: i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }));      
        } catch(err) {
          console.log(err);
        }
      },
      setClose(close){
        this.close = close;
      },
      saveItem(){
        this.item.inb_lastDate = this.lastDate;

        if (this.add){
          let uri = '/inboxes/add';
          //alert(this.item);
          this.item.cmp_id = String(this.$store.state.company);
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.post(uri, this.item)
          .then(response => {
            console.log(response);
            this.$router.replace({ name: 'DisplayInbox', params: { search: this.search } });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/inboxes/update';
          this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
          this.axios.post(uri, this.item)
          .then((response) => {
            if (this.close){
              this.$router.push({ name: 'DisplayInbox', params: { search: this.search } });
            }
            this.message = 'Registro guardado';
          })
          .catch((err) => {
            this.message = 'Error al guardar la información.';
          });
        }
      },
      getItem(id){
        let uri = '/inboxes/edit';
        var p = {};
        p.cmp_id = String(this.$store.state.company);
        p.id = id;
        this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          this.item = response.data;
          if (this.item.inb_user){
            this.inb_user = this.item.inb_user;
          }
          if (this.item.inb_lastDate != null){
            this.lastDate = this.item.inb_lastDate.toString().substring(0,10);
          }   

          this.item.permisos?.forEach(i => {
            i.usu_nmbre = this.usuarios.find(t => t.id == i.usu_id).text;
          });          
        });
      },
      cancel(){
        this.$router.replace({ name: 'DisplayInbox', params: { search: this.search } });
      },
      newPermiso() {
        this.permiso = {};
        this.addPerm = true;
      },
      addPermiso(i) {
        if (i.usu_id == null){
          alert('Seleccione el usuario');
          return;
        }

        var u = this.usuarios.find(t => t.id == i.usu_id);
        i.usu_id = u.id;
        i.usu_nmbre = u.text;

        if (this.addPerm) {
          this.item.permisos.push({ 
            usu_id: i.usu_id, 
            usu_nmbre: i.usu_nmbre,
            inb_envio: i.inb_envio
          });
        } else {
          this.item.permisos[this.editPerm] = { 
            _id: i._id,
            usu_id: i.usu_id,
            usu_nmbre: i.usu_nmbre,
            inb_envio: i.inb_envio
          };
        }
        this.permiso = {};
        this.addPerm = true;
        this.showModal = false;
      },
      editPermiso(i) {
        this.permiso = { 
          _id: this.item.permisos[i]._id,
          usu_id: this.item.permisos[i].usu_id,
          inb_envio: this.item.permisos[i].inb_envio
        };
        this.addPerm = false;
        this.editPerm = i;
        //this.indicesButtonText = 'Guardar cambios';
      },
      deletePermiso(i) {
        const response = confirm('Está seguro que desea eliminar?');
        if (response) {
          this.item.permisos.splice(i,1);
        }
      },    

    } // END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }

    /*
    #inb_user {
      text-transform: lowercase;
    }
    */

  </style>