<template>
  <!-- Modal -->
  <div class="modal" id="permisoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Agregar Permiso</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Usuario (*):</label>
                  <select v-model="permiso.usu_id" class="form-control col-md-8" autofocus required>
                    <option v-for="i in usuarios" v-bind:value="i._id">
                      {{ i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-11">
                <div class="form-group row">
                  <label class="col-md-4 col-form-label">Permitir envío:</label>
                  <input type="checkbox" class="form-control col-md-8" v-model="permiso.inb_envio">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="$emit('add-permiso', permiso)">Aceptar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//import { focus } from 'vue-focus';

export default {
  //directives: { focus: focus },
  props: ['permiso'],
  data() {
    return {
      //indice: {}
      usuarios: [],
      focused: false
    }
  },
  created: function(){
    this.fetchUsuario();
  },
  mounted: function() {
    //this.setFocus();
  },
  methods: {
    fetchUsuario(){
      var p = {};
      p.compania = this.$store.state.company;
      p.activos = true;
          
      let uri = '/usuarios/seguimiento';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          if (a.usu_nmbre.toLowerCase() > b.usu_nmbre.toLowerCase()) { return 1; };
          if (a.usu_nmbre.toLowerCase() < b.usu_nmbre.toLowerCase()) { return -1; };
          return 0;
        });
        this.usuarios = list;
      });
    },
    setFocus: function() {
      this.$refs.usu_id.focus();
    }
  }
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/
</style>